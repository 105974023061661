import { graphql, Link } from "gatsby"
import React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import { RenderComponent } from "../components/components"
import Layout from "../components/layout"
import Seo from "../components/seo"

import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import Section from "../components/03_organisms/Section/Section"
import LocationLink from "../components/02_molecules/LocationLink/LocationLink"
import { Helmet, useI18next } from "gatsby-plugin-react-i18next"
import { useTranslation } from "react-i18next"
import Button from "../components/01_atoms/Button/Button"
import Spacer from "../components/01_atoms/Spacer/Spacer"
import Back from "../components/01_atoms/Back/Back"

const LocationPage = ({ data, pageContext, location }) => {
  const pageData = data.nodeLocation
  const components = pageData?.relationships?.field_components
  const jobs = data.allJobItem?.edges
  const locations = data.allNodeLocation.nodes
  const { language } = useI18next()
  const { t } = useTranslation()

  return (
    <Layout>
      {pageData && (
        <>
          <Seo
            title={pageData.field_meta_title}
            description={pageData.field_meta_description}
          />
          <Helmet
            bodyAttributes={{
              class: `jobs-page jobs-page--${pageContext.country}`,
            }}
          />

          {pageContext.country !== "CA" ? (
            <>
              <ImageTextBlock
                height="100"
                className="hero"
                image={pageData.relationships.field_image}
                image_position="right"
                slanted
              >
                <Back to={`/${language}/jobs#locations`}>
                  {t("location_back")}
                </Back>
                <Spacer height={64} />

                <Heading size="xxl" spacing="l">
                  {pageData.title}
                </Heading>
                <Paragraph>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: pageData.body?.processed,
                    }}
                  />
                </Paragraph>
              </ImageTextBlock>

              {jobs && (
                <Section background="Primary blue" content id="jobs">
                  <Heading align="center" size="xl" spacing="xl">
                    {pageData.field_title_job_opportunities}
                  </Heading>

                  {jobs?.map(({ node: job }, index) => (
                    <LocationLink
                      key={index}
                      link={`/${language}/jobs/${job.attributes.slug}`}
                      index={index}
                      title={job.attributes.title}
                      filters={job.attributes.filters}
                      location={job.attributes.location?.city}
                      currentPath={location.pathname}
                    />
                  ))}

                  {jobs.length ? <Spacer height={32} /> : null}

                  {pageData.field_spontaneous_appl_url && (
                    <Section align="center">
                      <Paragraph align="center" spacing="l">
                        {jobs.length
                          ? t("location_no_result_title")
                          : t("location_no_jobs")}
                      </Paragraph>
                      <Button
                        to={pageData.field_spontaneous_appl_url?.url}
                        type="link"
                        external
                      >
                        {t("location_no_result_cta")}
                      </Button>
                    </Section>
                  )}
                </Section>
              )}

              <Section content>
                <Heading size="xl" align="center">
                  {pageData.field_title_components}
                </Heading>
              </Section>

              {components?.map((component, index) =>
                RenderComponent(component, index, locations)
              )}
            </>
          ) : (
            <>
              <ImageTextBlock
                height="100"
                className="hero"
                image={pageData.relationships.field_image}
                image_position="right"
                slanted
              >
                <Back to={`/${language}/jobs#locations`}>
                  {t("location_back")}
                </Back>
                <Spacer height={64} />

                <Heading size="xxl" spacing="l">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: pageData.body.processed,
                    }}
                  />
                </Heading>
              </ImageTextBlock>
            </>
          )}
        </>
      )}
    </Layout>
  )
}

export default LocationPage

export const query = graphql`
  query ($language: String!, $slug: String!, $country: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    nodeLocation(
      path: { alias: { eq: $slug } }
      langcode: { eq: $language }
      status: { eq: true }
    ) {
      title
      body {
        processed
      }
      field_spontaneous_appl_url {
        url
      }

      field_meta_description
      field_meta_title
      field_title_components
      field_title_job_opportunities
      relationships {
        field_image {
          ...imageFragment
        }
        field_country {
          name
          field_country
          relationships {
            field_background_color {
              name
            }
            field_image {
              ...imageFragment
            }
          }
        }
        field_components {
          ... on Node {
            ...carouselFragment
            ...imageTextFragment
            ...locationList
            ...testimonialsCarousel
            ...videoFragment
          }
        }
      }
    }
    allJobItem(
      filter: { attributes: { location: { country: { eq: $country } } } }
    ) {
      edges {
        node {
          attributes {
            location {
              country
              city
            }
            filters
            slug
            title
          }
        }
      }
    }
    allNodeLocation(
      filter: {
        status: { eq: true }
        langcode: { eq: $language }
        relationships: { field_country: { name: { ne: $country } } }
      }
      sort: { fields: field_weight, order: ASC }
    ) {
      nodes {
        path {
          alias
          langcode
        }
        relationships {
          field_country {
            name
            field_country
            relationships {
              field_background_color {
                name
              }
              field_image {
                ...imageFragment
              }
            }
          }
        }
      }
    }
  }
`
