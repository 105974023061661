import cx from "classnames"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Heading } from "../../01_atoms/Heading/Heading"
import * as styles from "./locationlink.module.scss"

const LocationLink = ({
  title,
  link,
  filters,
  location,
  index,
  className,
  currentPath,
}) => {
  return (
    <Link
      key={index}
      to={link}
      className={cx(className, styles.wrapper)}
      state={{ prevPath: currentPath }}
    >
      <div className={styles.title}>
        <Heading size="m">{title}</Heading>
      </div>
      <div className={styles.item}>
        {filters.map((filter, index) => (
          <span key={index}>{filter}</span>
        ))}
      </div>
      <div className={cx(styles.location, styles.item)}>
        <svg
          width={14}
          height={17}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 5c-1.1 0-2 .9-2 2s.9 2 2 2a2 2 0 1 0 0-4Zm0-5c3.27 0 7 2.46 7 7.15 0 3.12-2.33 6.41-7 9.85-4.67-3.44-7-6.73-7-9.85C0 2.46 3.73 0 7 0Z"
            fill="#F6F7F7"
          />
        </svg>
        <span>{location}</span>
      </div>

      <div className={styles.button}></div>
    </Link>
  )
}

LocationLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  filters: PropTypes.array,
  location: PropTypes.string,
  index: PropTypes.number,
}

LocationLink.defaultProps = {
  title: "",
  link: "",
  filters: [],
  location: "",
  index: 0,
}

export default LocationLink
